import React from "react";
import Layout from "../../components/Layouts";
import Img from "gatsby-image";
import katex from "katex";
import { Divider, Row, Col, Tag, Checkbox } from "antd";

import VegaChart from "../../components/VegaChart";

const metricPlots = [
  { value: "brush-224-metrics", label: "224PX" },
  // { value: "brush-300-metrics", label: "300PX" },
  { value: "brush-448-metrics", label: "448PX" },
  { value: "brush-600-metrics", label: "600PX" },
];
export default class BrushSelectionPlot extends React.Component {
  state = {
    display_metric_plots: metricPlots.map((item, idx) => {
      const obj = {};
      const { value } = item;
      obj[value] = idx === 2;
      return obj;
    }),
  };

  onMetricChange(e) {
    if (e && Array.isArray(e)) {
      const rows = this.state.display_metric_plots;
      const updated = this.state.display_metric_plots.map((item) => {
        const out = {};
        const key = Object.keys(item)[0];
        out[key] = e.includes(key);
        return out;
      });
      this.setState({ display_metric_plots: updated });
      console.log(updated);
    }
  }

  findItem(datalist, match) {
    const found = datalist.find((item) => item[match]);
    return found ? Object.values(found)[0] : false;
  }

  render() {
    const { name, url224, url300, url448, url600 } = this.props;
    const { display_metric_plots } = this.state;

    const displayMetrics = metricPlots.map((item) =>
      this.findItem(display_metric_plots, item["value"])
    );

    return (
      <div>
        <Checkbox.Group
          options={metricPlots}
          defaultValue={["brush-600-metrics"]}
          onChange={this.onMetricChange.bind(this)}
        />
        {displayMetrics[0] && (
          <>
            <h3
              className="is-size-6"
              style={{ textAlign: "center", marginBottom: 20 }}
            >
              ❮ 224px ❯
            </h3>
            <VegaChart
              id={`${name}-brush-224-metrics`}
              chartName={`${name}-brush-224-metrics`}
              specUrl={url224}
            ></VegaChart>
            <p style={{ fontSize: 12, textAlign: "center" }}>
              Left: VGG16 Baseline, Right: VGG16 + Decoder
            </p>
          </>
        )}

        {/* {displayMetrics[1] && (
          <>
            <VegaChart
              id={`${name}-brush-300-metrics`}
              chartName={`${name}-brush-300-metrics`}
              specUrl={url300}
            ></VegaChart>
            <p style={{ fontSize: 12, textAlign: "center" }}>
              Use mouse to select the area on the plot. Left: VGG16 Baseline,
              Right: VGG16 + Decoder
            </p>
          </>
        )} */}
        {displayMetrics[1] && (
          <>
            <h3
              className="is-size-6"
              style={{ textAlign: "center", marginBottom: 20 }}
            >
              ❮ 448px ❯
            </h3>
            <VegaChart
              id={`${name}-brush-448-metrics`}
              chartName={`${name}-brush-448-metrics`}
              specUrl={url448}
            ></VegaChart>
            <p style={{ fontSize: 12, textAlign: "center" }}>
              Left: VGG16 Baseline, Right: VGG16 + Decoder
            </p>
          </>
        )}

        {displayMetrics[2] && (
          <>
            <h3
              className="is-size-6"
              style={{ textAlign: "center", marginBottom: 20 }}
            >
              ❮ 600px ❯
            </h3>
            <VegaChart
              id={`${name}-brush-600-metrics`}
              chartName={`${name}-brush-600-metrics`}
              specUrl={url600}
            ></VegaChart>
            <p style={{ fontSize: 12, textAlign: "center" }}>
              Left: VGG16 Baseline, Right: VGG16 + Decoder
            </p>
          </>
        )}
      </div>
    );
  }
}
